import React, { SetStateAction } from 'react';
import TFFButton from '../../../../styled-components/TFFButton';

interface props {
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleConfirmationClick: () => void;
}

export const ConfirmationFooter: React.FC<props> = ({ rebookCloser, handleConfirmationClick }) => {
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20, marginLeft: 10 }}>
      <TFFButton buttonText="Cancel" outline variant="secondary" handleClick={() => rebookCloser(false)} />
      <div>
        <TFFButton buttonText="Confirm changes" variant="secondary" handleClick={() => handleConfirmationClick()} />
      </div>
    </div>
  );
};

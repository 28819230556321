import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const DecimalTextField = ({ serviceFee, setServiceFee }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(newValue) && parseFloat(newValue) <= 500) {
      setServiceFee(newValue);
    }
  };

  const handleBlur = () => {
    const numericValue = parseFloat(serviceFee) || 0;
    setServiceFee(numericValue.toFixed(2));
  };

  return (
    <TextField
      value={serviceFee}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{
        style: {
          width: '90px',
          height: '31px',
          padding: '4px',
          lineHeight: '23.4px',
          fontSize: '18px',
          textAlign: 'right',
          backgroundColor: '#FFFFFF',
        },
      }}
    />
  );
};

export default DecimalTextField;

import { AmendBookingResponse } from '@tff/types/TFF';
import AlternativeFlightCard from './AlternativeFlightCard';
import React, { SetStateAction } from 'react';
import TFFTypography from '../../../../styled-components/TFFTypography';
import { CombinationOffer } from '@tff/types/TFF/tff-flight-details';
import { TFF } from '@tff/types';

interface props {
  amendResponse?: AmendBookingResponse;
  selectedOffer: CombinationOffer | undefined;
  setSelectedOffer: (offer: CombinationOffer) => void;
  setSelectedOfferJourneys: (value: SetStateAction<TFF.Journey[]>) => void;
}

const AlternativeFlightCards: React.FC<props> = ({
  amendResponse,
  selectedOffer,
  setSelectedOffer,
  setSelectedOfferJourneys,
}) => {
  //TODO - this is the case where the BE retrieves an failure response, in which case we will have a future ticket to tackle this
  if (!amendResponse) {
    return (
      <div>
        No alternative flights to show for now. Note: this is the case where the BE retrieves an failure response, in
        which case we will have a future ticket to tackle this
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '16px 0' }}>
        <TFFTypography
          text="Alternative Flight Results"
          modifier="uppercase"
          color="color-theme-variant"
          fontWeight="bold"
          size="large"
        />
      </div>
      {Object.keys(amendResponse?.flightAlternatives?.CombinationOffers!).map((combinationOfferKey, index) => {
        const outboundJourneyId = combinationOfferKey.split('-')[0];
        const outboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
          journey => journey.Id === outboundJourneyId,
        );

        const inboundJourneyId = combinationOfferKey.split('-')[1];
        const inboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
          journey => journey.Id === inboundJourneyId,
        );

        const fareOffers: CombinationOffer[] | undefined =
          amendResponse?.flightAlternatives?.CombinationOffers?.[combinationOfferKey]?.Offers;

        return outboundJourney ? (
          <AlternativeFlightCard
            key={combinationOfferKey}
            outboundJourney={outboundJourney}
            inboundJourney={inboundJourney}
            fareOffers={fareOffers?.sort((a, b) => a.TotalAmount - b.TotalAmount)}
            selectedOffer={selectedOffer}
            handleOfferClick={(offer: CombinationOffer) => {
              setSelectedOffer(offer);
              setSelectedOfferJourneys(
                inboundJourney
                  ? [
                      {
                        ...outboundJourney,
                        CabinClass: offer.PriceClasses.Journeys[outboundJourney.Id],
                      },
                      {
                        ...inboundJourney!,
                        CabinClass: offer.PriceClasses.Journeys[inboundJourney!.Id],
                      },
                    ]
                  : [
                      {
                        ...outboundJourney,
                        CabinClass: offer.PriceClasses.Journeys[outboundJourney.Id],
                      },
                    ],
              );
            }}
          />
        ) : null;
      })}
    </div>
  );
};

export default AlternativeFlightCards;

import React, { SetStateAction } from 'react';
import TFFButton from '../../../../styled-components/TFFButton';

interface props {
  setRebookStep: (value: 'Search' | 'Alternatives' | 'Validate') => void;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleValidationClick: () => void;
}

export const AlternativeFlightsFooter: React.FC<props> = ({ setRebookStep, rebookCloser, handleValidationClick }) => {
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <TFFButton buttonText="Cancel" outline variant="secondary" handleClick={() => rebookCloser(false)} />
      <div style={{ display: 'flex', gap: '16px' }}>
        <TFFButton buttonText="New Search" outline variant="secondary" handleClick={() => setRebookStep('Search')} />
        <TFFButton buttonText="Accept" variant="secondary" handleClick={() => handleValidationClick()} />
      </div>
    </div>
  );
};
